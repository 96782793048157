<template>
  <div class="main">
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'roleManagement-add'">
          <el-button type="primary" size="small" @click="addRole"
            >添加角色</el-button
          >
          &nbsp;
          <!--<el-button size="small" @click="showDrawer">按条件搜索</el-button>-->
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
              v-loading="spinning"
              :data="data"
              row-key="id"
            >
              <el-table-column prop="roleName" label="角色名">
              </el-table-column>

              <el-table-column
                width="300"
                label="操作"
                align="center"
                v-if="
                  authVerify.verify(
                    'roleManagement-edit,roleManagement-delete,roleManagement-operation'
                  )
                "
              >
                <!--<template slot="header" slot-scope="scope">
                    <el-button  size="mini"
                               @click="editRole(scope.row)">搜索
                    </el-button>
                    <el-button  size="mini"
                               @click="editRole(scope.row)">添加用户
                    </el-button>
                </template>-->
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-s-operation"
                    size="mini"
                    v-auth="'roleManagement-operation'"
                    @click="editRoleAuthority(scope.row)"
                    >权限分配
                  </el-button>
                  <el-button
                    icon="el-icon-edit-outline"
                    size="mini"
                    v-auth="'roleManagement-edit'"
                    @click="editRole(scope.row)"
                    >编辑
                  </el-button>
                  <el-button
                    icon="el-icon-delete"
                    size="mini"
                    v-auth="'roleManagement-delete'"
                    @click="deleteRole(scope.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

          <div class="block">
            <el-pagination
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              @current-change="handleCurrentChange"
              :total="totalCount"
            >
            </el-pagination>
          </div>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-modal v-model="addRoleVisible" title="新增角色" width="550px">
          <template slot="footer">
            <a-button @click="addRoleVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddRole"> 确认 </a-button>
          </template>
          <a-form-model
            :model="roleFormValue"
            ref="roleRefFormAdd"
            :rules="roleRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item label="角色名" prop="roleName">
              <a-input v-model="roleFormValue.roleName" allowClear placeholder="请输入角色名" />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-modal v-model="editRoleVisible" title="编辑角色" width="600px">
          <template slot="footer">
            <a-button @click="editRoleVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditRole"> 确认 </a-button>
          </template>
          <a-form-model
            :model="roleFormValue"
            ref="roleRefFormEdit"
            :rules="roleRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item label="角色名" prop="roleName">
              <a-input v-model="roleFormValue.roleName" allowClear placeholder="请输入角色名" />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </template>

    <!--编辑用户权限-->
    <a-modal
      v-model="editRoleAuthorityVisible"
      :title="'角色权限分配 - ' + currentUserData.roleName"
      width="900px"
      class="a-modal-sty"
    >
      <template slot="footer">
        <a-button type="primary" @click="handleCurAllPick">
          全选当前
        </a-button>
        <a-button @click="handleCurAllPick('cancel')">
          取消全选当前
        </a-button>
        <a-button @click="editRoleAuthorityVisible = false">取消</a-button>
        <a-button type="primary" @click="toEditRoleAuthority">
          确认
        </a-button>
      </template>
      <a-tabs :activeKey="roleTabKey" @change="handleTabChange">
        <a-tab-pane key="1" tab="菜单权限">
          <a-spin :spinning="roleAuthoritySpin" tip="加载菜单权限中">
            <a-tree
                v-model="roleMenuIds"
              checkable
              :replaceFields="{children:'children', title:'title', key:'id'}"
                :defaultExpandAll="true"
              :tree-data="allMenuList"
              @check="onAuthSelectChange"
            />
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="2" tab="板块权限管理" force-render>
          <a-spin :spinning="pageAuthoritySpin" tip="加载板块权限中">
            <a-tree
                v-model="rolePageAuthorityIds"
              checkable
              :replaceFields="{children:'children', title:'describe', key:'id'}"
                :defaultExpandAll="true"
              :tree-data="allPageAuthorituList"
              @check="onRoleSelectChange"
            />
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="3" tab="小程序权限管理">
          <a-spin :spinning="miniAuthoritySpin" tip="加载板块权限中">
            <a-tree
                v-model="roleMiniAuthorityIds"
                checkable
                :defaultExpandAll="true"
                :replaceFields="{children:'children', title:'describe', key:'id'}"
                :tree-data="allMiniAuthorituList"
                @check="onMiniSelectChange"
            />
          </a-spin>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <!--搜索-->
    <template>
      <div>
        <a-drawer
          title="搜索角色"
          width="520"
          placement="right"
          :closable="false"
          :visible="drawerVisible"
          @close="onDrawerClose"
        >
          <div>
            <div>
              <a-button @click="removeAllQuery">清空</a-button>&nbsp;
              <a-button type="primary" @click="searchQuery">搜索</a-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roleAuthoritySpin: true,
      pageAuthoritySpin: true,
      miniAuthoritySpin: true,
      roleAuthorityVisible: true,
      pageAuthorityVisible: true,
      miniAuthorityVisible: true,
      rolePageAuthorityIds: [],
      roleMiniAuthorityIds: [],
      allPageAuthorituList: [],
      allMiniAuthorituList: [],
      allMenuList: [],
      editRoleId: "",
      roleMenuIds: [],
      editRoleAuthorityVisible: false,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      drawerVisible: false,
      roleFormValue: {
        id: "",
        roleName: "",
      },
      roleRules: {
        roleName: [
          { required: true, message: "请输入角色名!", trigger: "blur" },
        ],
      },
      userQuery: {
        username: "",
        state: "",
        topOrganizationId: "",
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      addRoleVisible: false,
      editRoleVisible: false,
      data: [],
      organizationOneselfList: [],
      topOrganizationList: [],
      replaceFields: {
        children: "children",
        value: "id",
        key: "id",
        title: "name",
      },
      currentUserData: {},
      roleTabKey: '1'
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getAllMenuList();
    this.getAllPageAuthorityList();
    this.getAllMiniAuthorityList();
    this.getRoleData();
    /*this.getOrganizationOneselfList();
    this.getOnlyTopOrganizationList();*/
  },
  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "正常";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "禁用";
      } else {
        return "禁用";
      }
    },
    StateTypeFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "success";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {

    handleTabChange(e){
      this.roleTabKey = e
    },
    toEditRoleAuthority() {
      this.updateMenuIdsByRoleId();
      this.updatePageAuthorityIdsByRoleId();
      this.updateMiniAuthorityIdsByRoleId();
      this.editRoleAuthorityVisible = false;
      this.getRoleData();
    },
    updateMenuIdsByRoleId() {
      this.http.pageAuth.updateMenuIdsByRoleId({
        roleId: this.editRoleId,
        menus: this.roleMenuIds.join(",")
      })
        .then((response) => {
          if (response.data.status == 200) {
            this.$message.success(response.data.msg);
          } else {
            this.$message.warning(response.data.msg);
          }
        });
    },
    updatePageAuthorityIdsByRoleId() {
      this.http.pageAuth.updatePageAuthorityIdsByRoleId({
        roleId: this.editRoleId,
        pageAuthoritys: this.rolePageAuthorityIds.join(","),
      })
        .then((response) => {
          if (response.data.status == 200) {
            this.$message.success(response.data.msg);
          } else {
            this.$message.warning(response.data.msg);
          }
        });
    },
    updateMiniAuthorityIdsByRoleId() {
      this.http.pageAuth.updateMiniAuthorityIdsByRoleId({
        roleId: this.editRoleId,
        pageAuthoritys: this.roleMiniAuthorityIds.join(","),
      })
        .then((response) => {
          if (response.data.status == 200) {
            this.$message.success(response.data.msg);
          } else {
            this.$message.warning(response.data.msg);
          }
        });
    },

    getAllMenuList() {
      this.http.pageAuth.getAllMenuList()
        .then((response) => {
          if (response.data.status == 200) {
            this.allMenuList = response.data.data;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getAllPageAuthorityList() {
      this.http.pageAuth.getAllPageAuthorityList()
        .then((response) => {
          if (response.data.status == 200) {
            this.allPageAuthorituList = response.data.data;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getAllMiniAuthorityList() {
      this.http.pageAuth.getAllMiniAuthorityList()
        .then((response) => {
          if (response.data.status == 200) {
            let list = response.data.data;
            // list = this.handleList(list)
            this.allMiniAuthorituList = list
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    handleList(hlist){
      let that = this
      let list = []
      hlist.forEach(val=>{
        list.push(val.id)
        if(val.children){
          list = [...list,...that.handleList(val.children)]
        }
      })
      return list
    },
    editRoleAuthority(obj) {
      this.currentUserData = JSON.parse(JSON.stringify(obj))
      console.log(obj)
      this.roleAuthoritySpin = true;
      this.pageAuthoritySpin = true;
      this.miniAuthoritySpin = true;
      this.roleMenuIds = [];
      this.rolePageAuthorityIds = [];
      this.editRoleId = obj.id;
      this.getRoleMenuIds();
      this.getRolePageAuthorityIds();
      this.getMiniAuthorityIdsByRoleId();
      this.editRoleAuthorityVisible = true;
    },
    getRoleMenuIds() {
      let params = {
        roleId: this.editRoleId,
      };
      this.http.pageAuth.getMenuIdsByRoleId(params)
        .then((response) => {
          if (response.data.status == 200) {
            this.roleMenuIds = response.data.data;
            this.roleAuthoritySpin = false;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getRolePageAuthorityIds() {
      let params = {
        roleId: this.editRoleId,
      };
      this.http.pageAuth.getPageAuthorityIdsByRoleId(params)
        .then((response) => {
          if (response.data.status == 200) {
            this.rolePageAuthorityIds = response.data.data;
            this.pageAuthoritySpin = false;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getMiniAuthorityIdsByRoleId(){
      let params = {
        roleId: this.editRoleId,
      };
      this.http.pageAuth.getMiniAuthorityIdsByRoleId(params)
        .then((response) => {
          if (response.data.status == 200) {
            this.roleMiniAuthorityIds = response.data.data;
            this.miniAuthoritySpin = false;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRoleData();
    },
    showDrawer() {
      this.drawerVisible = true;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    getOrganizationOneselfList() {
      this.http.organization.getOrganizationOneselfList({}).then((res) => {
        if (res.data.status == 200) {
          this.organizationOneselfList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getOnlyTopOrganizationList() {
      this.http.organization.getOnlyTopOrganizationList().then((res) => {
        if (res.data.status == 200) {
          this.topOrganizationList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    toAddRole() {
      /*this.$message.warning(this.roleFormValue.organizationId);*/
      this.$refs.roleRefFormAdd.validate((valid) => {
        if (valid) {
          this.http.user.createRole(this.roleFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.addRoleVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getRoleData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteRole(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let params = {
            id: key.id,
          }
          this.http.user.deleteRole(params)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                self.getRoleData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    editRole(record) {
      console.log(record);
      this.cleanRole();
      this.roleFormValue = {
        id: record.id,
        roleName: record.roleName,
      };
      this.editRoleVisible = true;
        this.$refs.roleRefFormEdit.resetFields();
    },
    addRole() {
      this.cleanRole();
      this.addRoleVisible = true;
        this.$refs.roleRefFormAdd.resetFields();
    },
    cleanRole() {
      this.roleFormValue = {
        id: "",
        roleName: "",
      };
    },
    getRoleData() {
      // pagesize current 搜索的数据  旁边树的id
      let params = {
        /*username:this.userQuery.username,
        topOrganizationId:this.userQuery.topOrganizationId,
        state:this.userQuery.state,*/
        size: this.pageSize,
        current: this.currentPage,
      }
      this.http.user.getRoleListPage(params)
        .then((response) => {
          if (response.data.status == 200) {
            this.spinning = false;
            this.data = response.data.data.records;
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    toEditRole() {
      this.$refs.roleRefFormEdit.validate((valid) => {
        if (valid) {
          this.http.user.updateRole(this.roleFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.editRoleVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getRoleData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeAllQuery() {},
    searchQuery() {},
    onAuthSelectChange(selectedKeys, info) {
      this.roleMenuIds = selectedKeys;
    },
    onRoleSelectChange(selectedKeys, info) {
      this.rolePageAuthorityIds = selectedKeys;
    },
    onMiniSelectChange(selectedKeys, info) {
      this.roleMiniAuthorityIds = selectedKeys;
    },
      handleCurAllPick(type){
          if(type=='cancel'){
              // this.handleAllCancel()
              if(this.roleTabKey == '1'){
                  this.roleMenuIds = []
                  this.roleAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.roleAuthorityVisible = true
                  })
              }
              if(this.roleTabKey == '2'){
                  this.rolePageAuthorityIds = []
                  this.pageAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.pageAuthorityVisible = true
                  })
              }
              if(this.roleTabKey == '3'){
                  this.roleMiniAuthorityIds = []
                  this.miniAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.miniAuthorityVisible = true
                  })
              }
          }else{
              if(this.roleTabKey == '1'){
                  this.roleMenuIds = this.handleList(this.allMenuList)
                  this.roleAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.roleAuthorityVisible = true
                  })
              }
              if(this.roleTabKey == '2'){
                  this.rolePageAuthorityIds = this.handleList(this.allPageAuthorituList)
                  this.pageAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.pageAuthorityVisible = true
                  })
              }
              if(this.roleTabKey == '3'){
                  this.roleMiniAuthorityIds = this.handleList(this.allMiniAuthorituList)
                  this.miniAuthorityVisible = false
                  this.$nextTick(()=>{
                      this.miniAuthorityVisible = true
                  })
              }
          }
      },
  },
};
</script>

<style scoped>
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
.a-modal-sty /deep/ .ant-modal {
  height: 80% !important;
}
.a-modal-sty /deep/ .ant-modal-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.a-modal-sty /deep/ .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
